<template>
  <div class="container">
    <!-- 按条件筛选查找数据 -->
    <el-form
      ref="form"
      :inline="true"
      :model="formInline"
      small="size"
      label-width="120px"
    >
      <el-form-item label="医生姓名：">
        <el-input
          v-model.trim="formInline.doctorName"
          v-check-permission
          placeholder="请输入医生姓名"
        />
      </el-form-item>

      <el-form-item label="患者姓名：">
        <el-input
          v-model.trim="formInline.patientName"
          v-check-permission
          placeholder="请输入患者姓名"
        />
      </el-form-item>

      <el-form-item label="订单号：">
        <el-input
          v-model.trim="formInline.id"
          v-check-permission
          placeholder="请输入订单号"
        />
      </el-form-item>

      <el-form-item label="开方时间：">
        <el-date-picker
          v-model="formInline.creatTime"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
        />
      </el-form-item>

      <el-form-item label="审核人：">
        <el-input
          v-model.trim="formInline.auditBy"
          v-check-permission
          placeholder="请输入审核人"
        />
      </el-form-item>
      <el-form-item label="审核状态：">
        <el-select v-model="formInline.auditStatus">
          <el-option
            v-for="item in auditStatusList"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="医师签名状态：">
        <el-select v-model="formInline.createSignStatus">
          <el-option
            v-for="item in createSignStatusList"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="药师签名状态：">
        <el-select v-model="formInline.auditSignStatus">
          <el-option
            v-for="item in auditSignStatusList"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="机构盖章状态：">
        <el-select v-model="formInline.signatureStatus">
          <el-option
            v-for="item in signatureStatusList"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="queryData()"> 查询 </el-button>
        <el-button type="primary" @click="resetData()"> 重置 </el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="orderListData"
      border
      class="width-96"
    >
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column prop="id" label="处方订单" align="center" />
      <el-table-column prop="doctorName" label="医生姓名" align="center" />
      <el-table-column prop="patientName" label="患者姓名" align="center" />
      <el-table-column
        prop="diagnosisName"
        label="诊断"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="createSignStatus"
        label="医师签名状态"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ createSignStatusDic[scope.row.createSignStatus] }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="auditSignStatus"
        label="药师签名状态"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ auditSignStatusDic[scope.row.auditSignStatus] }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="signatureStatus"
        label="机构盖章状态"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ signatureStatusDic[scope.row.signatureStatus] }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="auditStatus" label="审核状态" align="center">
        <template slot-scope="scope">
          <span>{{ auditStatusDic[scope.row.auditStatus] }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="auditBy" label="审核人" align="center" />
      <el-table-column prop="auditTime" label="审核时间" align="center" />
      <el-table-column prop="createTime" label="开方时间" align="center" />
      <el-table-column
        label="操作"
        show-overflow-tooltip
        width="200"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 0)"
          >
            查看
          </el-button>
          <el-button
            v-if="
              scope.row.createSignStatus === 'SUCCESS' &&
              scope.row.auditSignStatus === 'SIGNING' &&
              scope.row.auditStatus === 'SUCCESS' &&
              $checkPermission('PHARMACISTSIGNATURE', $route)
            "
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 1)"
          >
            药师签名
          </el-button>
          <el-button
            v-if="
              scope.row.createSignStatus === 'SUCCESS' &&
              ((scope.row.auditStatus === 'WAIT' &&
                scope.row.auditSignStatus === 'WAIT') ||
                (scope.row.auditStatus === 'SUCCESS' &&
                  scope.row.auditSignStatus === 'FAIL')) &&
              $checkPermission('PHARMACISTAUDIT', $route)
            "
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 2)"
          >
            药师审核
          </el-button>
          <el-button
            v-if="
              scope.row.createSignStatus === 'SUCCESS' &&
              scope.row.auditSignStatus === 'SUCCESS' &&
              scope.row.signatureStatus !== 'SUCCESS' &&
              scope.row.auditStatus === 'SUCCESS' &&
              $checkPermission('STAMP', $route)
            "
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 3)"
          >
            盖章
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页功能模块 -->
    <div class="padding-30">
      <el-pagination
        background
        :page-sizes="[10, 20, 50, 100, 200]"
        :current-page="pageInfo.page"
        :page-size="pageInfo.size"
        layout="->,total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <!-- <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="50%"
    >
      <iframe
        :src="signPage"
        frameborder="0"
        width="100%"
        height="600px"
      />
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="dialogVisible = false"
        >确 定</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import {
  auditStatusList,
  createSignStatusList,
  auditSignStatusList,
  auditStatusDic,
  createSignStatusDic,
  auditSignStatusDic,
  signatureStatusList,
  signatureStatusDic,
} from '@/utils/component/publicList';
import moment from 'moment';
import { formatWebUrl } from '@/utils/component/publicList';
export default {
  name: 'ServiceManageList',
  data() {
    return {
      auditStatusList: auditStatusList,
      auditStatusDic: auditStatusDic,
      createSignStatusList: createSignStatusList,
      createSignStatusDic: createSignStatusDic,
      auditSignStatusList: auditSignStatusList,
      auditSignStatusDic: auditSignStatusDic,
      signatureStatusList: signatureStatusList,
      signatureStatusDic: signatureStatusDic,
      dialogVisible: false,
      loading: false,
      signPage: '',
      formInline: {
        id: '',
        auditBy: '',
        doctorName: '',
        patientName: '',
        creatTime: '',
        auditStatus: '',
        auditSignStatus: '',
        createSignStatus: '',
        signatureStatus: '',
      },
      pageInfo: {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      },
      orderListData: [], // 服务列表
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getPrescriptionList();
  },
  methods: {
    // 分页方法调用
    handleSizeChange(currentPage) {
      this.pageInfo.size = currentPage;
      this.getPrescriptionList();
    },
    handleCurrentChange(currentPage) {
      this.pageInfo.page = currentPage;
      this.getPrescriptionList();
    },
    // 重置
    resetData() {
      this.formInline = {
        // 查询条件
        id: '',
        auditBy: '',
        doctorName: '',
        patientName: '',
        creatTime: '',
        auditStatus: '',
        auditSignStatus: '',
        createSignStatus: '',
        signatureStatus: '',
      };
      this.pageInfo = {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      };
      this.getPrescriptionList();
    },
    queryData() {
      this.pageInfo.page = 1;
      this.getPrescriptionList();
    },
    // 分页查询问诊类型列表
    getPrescriptionList() {
      this.loading = true;
      const params = {
        ...this.formInline,
        createStartTime:
          this.formInline.creatTime !== '' && this.formInline.creatTime !== null
            ? moment(this.formInline.creatTime[0]).format('YYYY-MM-DD HH:mm:ss')
            : '',
        createEndTime:
          this.formInline.creatTime !== '' && this.formInline.creatTime !== null
            ? moment(this.formInline.creatTime[1]).format('YYYY-MM-DD HH:mm:ss')
            : '',
        page: {
          done: true,
          pageIndex: this.pageInfo.page,
          pageSize: this.pageInfo.size,
        },
      };
      if (this.formInline.confirmStatus === '') {
        delete params.confirmStatus;
      }
      this.$api
        .prescriptionPageQuery(params)
        .then((res) => {
          this.orderListData = res.data.data;
          this.pageInfo.total = Number(res.data.total); // 获取总共有多少条数据
          this.loading = false;
        })
        .catch(() => {});
    },
    // 导出列表
    /**
     * 列表中数据的操作方法
     * 0 - 查看
     * 1 - 签名
     * 2 - 审核
     * 3 - 盖章
     */
    dataOperation(item, type) {
      console.log('打印这个处方item', item);
      switch (type) {
        case 0:
          this.$router.push({
            path: '/orderManage/prescriptionDetail',
            query: {
              id: item.id,
            },
          });
          break;
        case 1:
          this.pharmacistSignature(item);
          break;
        case 2:
          this.$router.push({
            path: '/orderManage/prescriptionAudit',
            query: {
              id: item.id,
            },
          });
          break;
        default:
          this.signature(item);
          break;
      }
    },
    // 药师签名
    pharmacistSignature(data) {
      // this.dialogVisible = true;
      var isMainOms = window.__POWERED_BY_QIANKUN__ ? '/main-oms' : '';
      var address = 'https://' + window.location.hostname;
      var url = formatWebUrl({
        path: `${address}/operation-gateway/doctor-management-aggregate-service/truest/getSignQRCode`,
        query: {
          openId: data.auditTrustOpenId,
          uniqueId: data.auditUniqueId,
          redirectUrl: encodeURIComponent(
            `${address}${isMainOms}/doctor-oms/orderManage/prescriptionList`
          ),
        },
      });
      window.location.href = url;
    },
    // 签章
    signature(data) {
      this.$api.trustSignature({ id: data.id }).then((res) => {
        if (res.code === 0) {
          this.$message.success('签章成功');
          this.getPrescriptionList();
        }
      });
    },
  },
};
</script>
<style lang="css">
.el-table th.gutter {
  display: table-cell !important;
}
</style>
<style lang="scss" scoped>
.container {
  margin-top: 20px;
}

.width-96 {
  width: 96%;
}

.pagination-padding-r {
  padding-right: 60px;
}

.demo-input-suffix {
  padding-top: 30px;
}

.padding-30 {
  padding-right: 56px;
  margin-top: 10px;
}

.circle {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  border: solid rgb(0, 0, 0) 1px;
  border-radius: 50%;

  /* 宽度和高度需要相等 */
}

.flex {
  display: flex;
}

.orderStatic {
  padding: 10px 0;
}

.labelStyle {
  width: 8%;
  height: 35px;
  margin-right: 1px;
  font-size: 14px;
  text-align: center;
}

.active {
  color: #eee;
  background-color: #409eff;
}
</style>
